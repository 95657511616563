.checkout-list {
  margin: 0;
  padding: 0;
  background: white;
  border-radius: 16px;
  list-style-type: none;
}

.checkout-list hr {
  border: none;
  border-top: 1px solid rgb(246, 246, 246);
}

.checkout-list-item {
  padding: 16px 12px;
  display: grid;
  grid-template-columns: 96px 4fr;
  grid-template-rows: minmax(96px, auto);
  gap: 16px;
  border-radius: var(--border-radius);
}

.checkout-list-item:hover {
  background: var(--secondary-color);
  text-decoration: none;
}

.checkout-list-item-picture img {
  max-width: 100%;
  height: 100%;
  max-height: 96px;
  border-radius: var(--border-radius);
}

.checkout-list-item-title a {
  color: inherit;
}

.checkout-list-item-title span {
  font-size: 1rem;
  color: var(--font-grey);
  font-weight: 500;
}

.checkout-list-item-body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 8px;
}

.checkout-list-item-title,
.checkout-list-item-description {
  display: flex;
  justify-content: space-between;
}

.checkout-list-item-title .total-price {
  min-width: 7.5rem;
  text-align: right;
  font-weight: bold;
}

.checkout-list-item-description {
  align-items: center;
  color: #777;
  font-size: 0.875rem;
}

.checkout-list-item-description-counter {
  display: flex;
  align-items: center;
  gap: 8px;
}

.checkout-list-item-description .quantity {
  display: inline-block;
  width: 18px;
  text-align: center;
}

.checkout-list-item-description .per-quantity-value {
  min-width: 7rem;
  text-align: right;
  font-size: 0.875rem;
}

.checkout-list .remove-button {
  padding: 2px 4px;
  display: flex;
  align-items: center;
  justify-self: flex-end;
  gap: 4px;
  border-radius: var(--border-radius);
  color: var(--font-light-grey);
  font-size: 0.875rem;
  font-weight: 600;
  cursor: pointer;
}

.checkout-list .remove-button svg {
  stroke-width: 3px;
}

.checkout-list .remove-button:hover {
  color: hsl(0, 60%, 30%);
  background: rgb(230, 230, 230);
  font-weight: 600;
}

.checkout-list .counter-button {
  cursor: pointer;
  padding: 5px 15px;
  background: none;
  border: none;
  font-size: 1.1em;
  font-weight: bold;
  color: #777;
}

.checkout-list .counter-button:hover {
  border-radius: var(--border-radius);
  background: #eee;
  color: var(--font-grey);
}

@media screen and (max-width: 800px) {
  .checkout-list {
    padding: 0 5px;
  }
  .checkout-list-item {
    grid-template-columns: 96px 4fr;
    grid-template-rows: minmax(96px, auto);
    padding: 12px 0;
  }
  .checkout-list-item-picture img {
    max-height: 96px;
  }
  .checkout-list-item-title span {
    font-size: 1rem;
  }
  .checkout-list-item-description {
    gap: 4px;
    font-size: 0.875rem;
  }
  .checkout-list-item-description .per-quantity-value {
    font-size: 0.875rem;
  }
  .checkout-list .counter-button {
    padding: 6px;
  }
  .checkout-list .remove-button {
    font-size: 0.75rem;
  }
}
