.password-field {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: rgb(229 231 235);
  border-radius: var(--border-radius);
  background-color: rgb(229 231 235);
  outline-color: var(--primary-color);
}

.password-eyeball {
  padding: 16px;
}

.password {
  flex: 1;
}
