.features-section {
  padding: 24px 0 36px;
  min-height: 220px;
  display: flex;
  align-items: flex-start;
  justify-content: space-evenly;
  background: #f2f3f4;

  .feature {
    padding: 16px 28px;
    box-sizing: border-box;
    width: 280px;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: var(--border-radius);
  }

  .feature .icon-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 96px;
    height: 96px;
    background: var(--primary-color);
    border-radius: 50%;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  }

  .feature .icon-wrapper svg {
    width: 48px;
    height: 48px;
    color: white;
  }

  .feature .label {
    margin: 32px 0 12px;
    font-size: 1.125rem;
    font-weight: 600;
    text-align: center;
  }

  .feature .description {
    margin: 0;
    font-size: 1rem;
    line-height: 1.6;
    text-align: center;
  }

  @media only screen and (max-width: 800px) {
    padding: 48px 0;
    flex-direction: column;
    align-items: center;
    gap: 24px;
  }
}
