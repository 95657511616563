.public-nav-bar {
  position: fixed;
  top: 0;
  height: var(--header-height);
  width: 100%;
  background: white;
  color: var(--font-grey);
  box-shadow: 0 3px 10px rgb(0 0 0 / 15%);
  font-weight: 500;
  letter-spacing: 2px;
  transition: 0.2s ease;
  z-index: 1;
}

.public-nav-bar-nav {
  padding: 0 32px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
}

.public-nav-bar-nav-logo {
  display: flex;
  align-items: center;
  width: 112px;
  height: 80px;
}

.public-nav-bar-nav-logo img {
  max-height: 100%;
}

.public-nav-bar .menu-button {
  padding: 8px;
  border-radius: var(--border-radius);
  transition: 0.2s ease;
  cursor: pointer;
}

.public-nav-bar .menu-button:hover {
  background: var(--secondary-color);
}

.public-nav-bar-nav .site-map {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.public-nav-bar-nav .site-map a {
  padding: 12px 16px;
  border-radius: var(--border-radius);
  font-size: 1rem;
  font-weight: 600;
}

.public-nav-bar-nav .site-map a:hover {
  text-decoration: none;
  background: var(--secondary-color);
}

.public-nav-bar-nav .public-auth-links {
  width: 280px;
  margin-right: 12px;
  display: flex;
  gap: 8px;
  justify-content: flex-end;
}

.public-nav-bar-nav .public-auth-links a {
  padding: 12px 16px;
  border-radius: var(--border-radius);
  font-size: 1rem;
  font-weight: 800;
  text-decoration: none;
}

.public-nav-bar-drop-down-menu {
  position: fixed;
  top: 0;
  right: 0;
  min-height: 100vh;
  min-width: 300px;
  height: 100%;
  margin: 0;
  padding: 0;
  list-style-type: none;
  background-color: white;
  box-shadow: 0 3px 10px rgb(0 0 0 / 15%);
  transition: 0.2s ease;
}

.public-nav-bar-drop-down-menu .action-buttons {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.public-nav-bar-drop-down-menu li a {
  margin: auto;
  width: 90%;
  display: block;
  padding: 16px;
  box-sizing: border-box;
  border-radius: var(--border-radius);
  text-decoration: none;
  color: var(--font-grey);
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
}

.public-nav-bar-drop-down-menu a:hover {
  background: var(--secondary-color);
  font-weight: bold;
  text-decoration: none;
  transition: 0.2s ease;
}

.public-nav-bar-nav .primary {
  background: var(--primary-color);
  color: white;
  text-align: center;
}

.public-nav-bar-nav .primary:hover {
  background: var(--primary-color-hover);
}

.public-nav-bar-nav .secondary {
  background: var(--secondary-color);
  color: var(--font-grey);
  text-align: center;
}

.public-nav-bar-nav .secondary:hover {
  background: var(--secondary-color-hover);
}

.public-nav-bar-drop-down-menu hr {
  border: none;
  border-top: 1px solid var(--secondary-color);
}

.public-nav-bar-drop-down-menu .close-button {
  padding: 25px;
  text-align: right;
  cursor: pointer;
}

.public-nav-bar-drop-down-menu .close-button-icon {
  padding: 4px;
  border-radius: var(--border-radius);
}

.public-nav-bar-drop-down-menu .close-button-icon:hover {
  background: var(--secondary-color);
}

@media screen and (max-width: 800px) {
  .public-nav-bar-nav {
    padding: 0 8px;
  }
  .public-nav-bar-nav .site-map {
    display: none;
  }
  .public-nav-bar-nav .public-auth-links {
    width: 100%;
    margin-right: 4px;
  }
  .public-nav-bar-nav .public-auth-links a {
    padding: 8px 12px;
    font-size: 1rem;
  }
  .public-nav-bar-nav .public-auth-links a:first-child {
    display: none;
  }
}
