.market-page {
  width: 100%;
  height: 100%;
  padding: 0 32px;
  box-sizing: border-box;
}

.market-page-breadcrumbs {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
}

@media screen and (max-width: 800px) {
  .market-page {
    padding: 0 4px;
  }

  .market-page-breadcrumbs {
    padding: 0 12px;
  }
}
