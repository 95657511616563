.info-list {
  .primary {
    font-weight: 600;
  }

  .secondary {
    color: var(--font-light-grey);
    font-size: 0.875rem;
  }
}
