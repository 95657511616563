.blockquote {
  margin: 0;
  padding: 24px;
  display: flex;
  align-items: center;
  gap: 16px;
  border-radius: var(--border-radius);
  background: var(--secondary-color);
  line-height: 1.6;
  min-inline-size: 30ch;

  svg {
    width: 1.5rem;
    height: 1.5rem;
    flex: none;
  }
}
