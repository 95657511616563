.carousel-wrapper {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.carousel-wrapper .menu {
  display: flex;
  justify-content: space-between;
}

.carousel-wrapper .menu .title {
  font-size: 1.75rem;
  font-weight: 600;
}

.carousel-wrapper .menu .controls {
  display: flex;
  align-items: center;
  gap: 4px;
}

.carousel-wrapper .menu .control-btn {
  padding: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: var(--border-radius);
  background: var(--secondary-color);
  font-size: 1.25rem;
  font-weight: 600;
}

.carousel-wrapper .menu .control-btn:hover {
  background: var(--secondary-color-hover);
}

.carousel-wrapper .carousel {
  display: flex;
  height: 80vh;
}

.carousel-wrapper .carousel:hover {
  transition: 0.5s ease-in;
}

.carousel-wrapper .carousel .slick-list {
  width: 100vw;
  height: 80vh;
}

.carousel-wrapper .carousel .slick-list .slick-track {
  height: 100%;

  .slick-slide > div {
    height: 100%;
  }
}

@media only screen and (max-width: 800px) {
  .carousel-wrapper .menu .title {
    font-size: 1.5rem;
  }
  .carousel-wrapper .carousel {
    height: 60vh;
  }
  .carousel-wrapper .carousel .slick-list {
    height: 60vh;
  }
}
