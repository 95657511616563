.review-application-page {
  margin: auto;
  max-width: 50vw;
  color: var(--font-grey);
  box-sizing: border-box;
}

.review-application-page .field {
  margin: 5px 0;
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.review-application-page .field .label {
  text-align: left;
}

.review-application-page .field .value {
  text-align: right;
}

.review-application-page blockquote {
  margin: auto;
  margin-bottom: 16px;
  width: fit-content;
  padding: 16px;
  border-radius: var(--border-radius);
}
.review-application-page section {
  padding: 24px;
  box-sizing: border-box;
  border-radius: var(--border-radius);
  background: var(--secondary-color);
}

.review-application-page h2 {
  margin-top: 0px;
}

.review-application-page hr {
  margin: 12px 0;
  border: none;
}

.review-application-page footer {
  height: 200px;
}

.staff-form-btn {
  margin-top: 16px;
  padding: 16px 24px;
  border: none;
  border-radius: var(--border-radius);
  font-family: inherit;
  font-weight: bold;
  background: var(--primary-color);
  color: white;
  cursor: pointer;
}

.staff-form-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.staff-form-footer a {
  margin-top: 16px;
  padding: 16px 24px;
  border: none;
  border-radius: var(--border-radius);
  font-family: inherit;
  font-size: 0.875rem;
  font-weight: bold;
  background: var(--font-grey);
  color: white;
  cursor: pointer;

  &:hover {
    text-decoration: none;
  }
}

.staff-form-footer .action-buttons {
  display: flex;
  gap: 8px;
}

@media only screen and (max-width: 800px) {
  .review-application-page {
    margin: 0;
    padding: 0 4px;
    max-width: 100vw;
  }

  .review-application-page .horeca-staff-form-section,
  .review-application-page .retail-staff-form-section {
    .field {
      display: flex;
      flex-direction: column;
    }
  }
}
