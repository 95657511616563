.application-review-page {
  max-width: 896px;
}

.application-review-page button {
  padding: 10px 20px;
  border: none;
  font-family: inherit;
}

.application-review-page .heading {
  margin: 24px 0;
  display: flex;
  align-items: center;
  gap: 20px;
}

.application-review-page .heading a svg {
  stroke-width: 3px;
}

.application-review-page blockquote {
  margin: 0;
  font-size: 1rem;
  line-height: 1.75;
}

.application-review-page .outlets {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.application-review-page h4 {
  font-size: 1.25rem;
}

.application-review-page .submit-for-review-form {
  margin: 48px 0;
  display: flex;
  flex-direction: column;
  gap: 36px;

  button {
    border-radius: var(--border-radius);
    padding: 16px 32px;
    background: var(--primary-color);
    color: white;
    font-weight: 600;
    font-size: 1rem;
    cursor: pointer;
  }

  button:hover {
    background: var(--primary-color-hover);
    transition: 0.1s ease-in;
  }
}

.terms {
  label {
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 1rem;
  }

  input {
    border: 2px solid var(--font-grey);
    border-radius: 4px;
    width: 16px;
    height: 16px;
    cursor: pointer;
  }

  input:hover {
    background: var(--matrix-green);
    transition: 0.1 ease-in;
  }
}

.application-review-page {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
  }
}
