.application-status-rejected-page {
  margin-top: 70px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.text-container {
  margin: auto;
  display: flex;
  flex-direction: column;

  .title {
    margin: 0px;
    color: #374151;
    font-size: 6.5em;
    font-weight: 600;
  }

  p {
    margin: 0px;
    font-size: 1.4rem;
    line-height: 1.75;
  }

  button {
    margin-left: 30px;
    background-color: var(--primary-color);
    color: white;
  }
}

.image-container {
  margin: auto;
  display: flex;

  img {
    margin-left: 10px;
  }
}
