.center {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.start {
  margin-top: 20vh;
  width: 100%;
  display: flex;
  margin-left: 15vw;
}

.message-loader {
  width: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;

  .message {
    display: flex;
    flex-direction: column;
  }

  .message p {
    margin: 0;
    font-size: 1rem;
    line-height: 1.4;
    color: var(--font-light-grey);
    text-align: center;
    max-inline-size: 40ch;
  }
}

@media screen and (max-width: 800px) {
  .start {
    margin-top: 15vh;
    margin-left: 0px;
    justify-content: center;
  }

  .message {
    padding: 0 12px;
  }

  .message p {
    font-size: 1rem;
  }
}
