.orders-page {
  height: 100%;
  width: 100%;
  color: var(--font-grey);
  box-sizing: border-box;
  margin: auto;
  max-width: var(--desktop-page-limit);
}

.orders-page-header h1 {
  font-size: 1.75rem;
}

.orders-page .list {
  display: flex;
  flex-direction: column;

  @media only screen and (min-width: 801px) {
    display: none;
  }
}

.orders-page .list .year {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 5px 0;
}

.orders-page .list .year h2 {
  margin: 0;
}

.orders-page .list .year hr {
  width: 90%;
  border: none;
  border-top: 1px solid #ccc;
  margin: 16px auto;
}

.orders-page .list .year .months {
  padding: 0 0 16px;
  display: flex;
  flex-direction: column;
  gap: 16px 0;
}

.orders-page .month-timestamp {
  margin: auto;
  width: fit-content;
  text-align: center;
  padding: 4px 24px;
  background: var(--secondary-color);
  border-radius: var(--border-radius);
  font-weight: 600;
  font-size: 0.875rem;
}

.order {
  padding: 24px 36px;
  display: flex;
  gap: 16px;
  border-radius: var(--border-radius);
  color: var(--font-grey);
  user-select: none;
}

.order .order-image {
  width: 80px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: var(--border-radius);
  background: var(--secondary-color);
}

.order .order-image p {
  color: rgb(0, 0, 0, 0.1);
  font-size: 1.5rem;
  font-weight: bold;
  letter-spacing: 1px;
}

.order .order-info-wrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.order .order-info {
  display: flex;
  flex-direction: column;
  gap: 8px;
  font-weight: 600;
}

.order .order-info .order-info-detailed {
  display: flex;
  align-items: center;
  gap: 8px;
}

.order .order-info .invoice-number {
  font-size: 1.25rem;
}

.order .order-info .total {
  font-size: 1rem;
}

.order .payment-info {
  display: flex;
  justify-content: space-between;
}

.order .order-date {
  color: #777;
  font-size: 0.875rem;
}

.orders-page .checkbox {
  cursor: pointer;
}

.orders-page .checkbox svg {
  width: 24px;
  height: 24px;
  fill: var(--font-grey);
}

.orders-page .action-buttons {
  padding: 0 15px;
  display: flex;
  gap: 15px;
  justify-content: flex-end;
}

.orders-page .action-buttons > * {
  margin-bottom: 25px;
}

.orders-page .view-all-button {
  background: var(--secondary-color);
}

.orders-page .payment-button {
  padding: 8px 24px;
  border: none;
  border-radius: var(--border-radius);
  background: var(--primary-color);
  color: white;
  font-weight: 600;
}

.orders-page .payment-button:hover {
  background: var(--primary-color-hover);
}

.orders-page .outstanding-and-checkout-button {
  padding: 8px 24px;
  border-radius: var(--border-radius);
  background: var(--secondary-color);
}

.orders-page .outstanding-and-checkout-button:hover {
  background: var(--secondary-color-hover);
}

.orders-page .outstanding-and-checkout-button a {
  color: inherit;
  text-decoration: none;
}

.orders-page .number-indicator {
  padding: 5px 10px;
  border-radius: 24px;
  background: #ddd;
  font-size: 1rem;
  font-weight: 800;
  user-select: none;
}

.orders-table-wrapper {
  padding: 0px 24px 24px;
  border: 1px solid rgb(246, 246, 246);
  border-radius: var(--border-radius);
}

.orders-table {
  margin: auto;
  width: 100%;
  max-width: 1100px;
  border-collapse: collapse;
  text-align: center;

  .order-image {
    width: 80px;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: var(--border-radius);
    background: var(--secondary-color);
    color: rgb(0, 0, 0, 0.1);
    font-weight: 600;
    letter-spacing: 1px;
  }

  .invoice-number {
    font-weight: 600;
  }

  .total {
    font-weight: 600;
  }

  thead {
    tr {
      border-bottom: 1px solid rgb(246, 246, 246);

      th {
        padding: 24px;
      }
    }
  }

  tbody {
    tr {
      border-bottom: 1px solid rgb(246, 246, 246);
      cursor: pointer;

      &:last-child {
        border: none;
      }

      td {
        padding: 16px;

        &:first-child {
          border-radius: var(--border-radius) 0 0 var(--border-radius);
        }

        &:last-child {
          border-radius: 0 var(--border-radius) var(--border-radius) 0;
        }
      }
    }
  }

  @media only screen and (max-width: 800px) {
    display: none;
  }
}

@media screen and (max-width: 800px) {
  .orders-page {
    padding: 0 4px;
  }

  .orders-page-header {
    padding: 0 5px;
  }
  .orders-page-header h1 {
    font-size: 1.5rem;
  }

  .orders-page .list .year .months {
    gap: 8px;
  }

  .order {
    padding: 0 8px;
    gap: 12px;
  }

  .order .order-info .invoice-number {
    font-size: 1.125rem;
  }

  .order .order-info .total {
    font-size: 0.875rem;
  }

  .orders-page .action-buttons {
    justify-content: center;
  }
}
