html,
body,
#root {
  height: 100vh;
  margin: 0;
}

body {
  margin: 0;
  font-family:
    "Lato",
    -apple-system,
    BlinkMacSystemFont,
    "Segoe UI",
    "Roboto",
    "Oxygen",
    "Ubuntu",
    "Cantarell",
    "Fira Sans",
    "Droid Sans",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

img {
  animation: fadeIn 0.6s;
}

button {
  color: inherit;
}

a {
  color: var(--primary-color);
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

/**
* Resources:
* For colors, visit https://cssgradient.io/color-shades/ 
*/
:root {
  --primary-color: var(--bright-green);
  --primary-color-hover: var(--bright-green-lighter);
  --secondary-color: var(--cool-grey);
  --secondary-color-hover: var(--cool-grey-darker);
  --primary-font-color: var(--font-grey);
  --starbucks-green: rgb(0, 98, 65);
  --starbucks-green-lighter: rgb(0, 115, 76);
  --bright-green: rgb(26, 122, 58);
  --bright-green-lighter: rgb(31, 140, 67);
  --matrix-green: rgba(40, 160, 90, 0.5);
  --matrix-green-light: rgba(40, 160, 90, 0.067);
  --light-blue: rgba(48, 118, 199, 0.1);
  --cool-grey: rgb(244, 245, 247);
  --cool-grey-darker: rgb(238, 239, 241);
  --cherry-red: rgb(255, 74, 85);
  --font-black: rgb(17, 24, 39);
  --font-grey: rgb(55, 65, 81);
  --font-light-grey: rgb(107, 114, 128);
  --font-subheading-one: 3rem; /* 48px */
  --font-subheading-two: 1.5rem; /* 24px */
  --font-body-one: 1rem; /* 16px */
  --font-body-two: 0.875rem; /* 14px */
  --font-footer: 0.875rem; /* 14px */
  --product-height: 176px;
  --header-height: 70px;
  --tablet-breakpoint: 800px;
  --desktop-page-limit: 1024px;
  --public-image-height: 240px;
  --border-radius: 8px;

  @media screen and (max-width: 800px) {
    --header-height: 60px;
  }
}

@media screen and (max-width: 800px) {
  html {
    font-size: 14px;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
