.delivery-options {
  display: flex;
  flex-direction: column;
  gap: 16px;

  .title {
    font-weight: 600;
  }

  .options {
    display: flex;
    border-radius: var(--border-radius);

    .option {
      padding: 16px 24px;
      display: flex;
      align-items: center;
      gap: 8px;
      background: var(--secondary-color);
      font-weight: 600;
      cursor: pointer;

      svg {
        width: 16px;
        height: 16px;
      }

      &:nth-child(1) {
        border-radius: var(--border-radius) 0 0 var(--border-radius);
      }

      &:nth-child(2) {
        border-radius: 0 var(--border-radius) var(--border-radius) 0;
      }

      &:hover {
        transition: 0.5s ease;
      }

      &:hover:nth-child(1) {
        background: rgba(48, 118, 199, 0.3);
      }

      &:hover:nth-child(2) {
        background: rgb(255, 219, 227);
      }

      &.selected:nth-child(1) {
        background: rgba(48, 118, 199, 0.3);
      }

      &.selected:nth-child(2) {
        background: rgb(255, 219, 227);
      }
    }
  }
}
