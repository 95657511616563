.manage-applications-page {
  height: 100%;
  width: 100%;
  margin: auto;
  max-width: var(--desktop-page-limit);
}

.manage-applications-page h1 {
  color: var(--font-grey);
  font-size: 1.75rem;
}

.manage-applications-page .content {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.manage-applications-page .pill {
  width: fit-content;
  padding: 4px 8px;
  border-radius: var(--border-radius);
  font-size: 0.75rem;
  font-weight: 600;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.manage-applications-page .small-font {
  font-size: 0.875rem;
}

.manage-applications-page .uppercase {
  text-transform: uppercase;
  letter-spacing: 1px;
}

.manage-applications-page .horeca {
  color: hsl(36, 100%, 50%);
  background: hsla(36, 100%, 50%, 0.111);
}

.manage-applications-page .retail {
  color: hsl(0, 60%, 50%);
  background: hsla(0, 60%, 50%, 0.191);
}

.manage-applications-page .user {
  color: var(--font-grey);
  background: var(--secondary-color);
}

.manage-applications-page .admin {
  color: hsl(9, 82%, 34%);
  background: hsla(9, 82%, 34%, 0.392);
}

.manage-applications-page .customer {
  color: hsl(0, 0%, 40%);
}

.manage-applications-page .action {
  padding: 2px 4px;
  display: flex;
  align-items: center;
  justify-self: flex-end;
  gap: 4px;
  border-radius: var(--border-radius);
  color: hsl(0, 60%, 30%);
  font-size: 0.875rem;
  font-weight: 600;
  cursor: pointer;
}

.manage-applications-page .action svg {
  stroke-width: 3px;
}

.manage-applications-page .action:hover {
  background: rgb(230, 230, 230);
  font-weight: 600;
}

@media screen and (max-width: 800px) {
  .manage-applications-page {
    width: fit-content;
    padding: 0 4px;
  }

  .manage-applications-page h1 {
    color: var(--font-grey);
    font-size: 1.5rem;
  }

  .manage-applications-page .action {
    font-size: 0.75rem;
  }
}
