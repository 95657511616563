.notification-center {
  padding: 8px;
  display: flex;
  flex-direction: column;
  border: 1px solid rgb(246, 246, 246);
  border-radius: var(--border-radius);

  .header {
    padding: 8px;
    border-bottom: 1px solid rgb(246, 246, 246);
    .title {
      margin: 0px;
      color: var(--font-grey);
      font-size: 1.25rem;
    }
  }

  .list {
    padding-top: 8px;
    display: flex;
    flex-direction: column;
  }
}
