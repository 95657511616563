.button {
  width: fit-content;
  padding: 16px 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  box-sizing: border-box;
  border-radius: var(--border-radius);
  border: none;
  background: var(--secondary-color);
  color: inherit;
  font-family: inherit;
  font-size: 1rem;
  font-weight: 600;
  text-align: center;
  cursor: pointer;

  svg {
    width: 1.125rem;
    height: 1.125rem;
  }

  &:hover {
    text-decoration: none;
    background: var(--secondary-color-hover);
  }
}

.wide {
  width: 100%;
}

.green {
  color: white;
  background: var(--primary-color);

  &:hover {
    background: var(--primary-color-hover);
  }
}

.disabled {
  filter: brightness(90%);
}
