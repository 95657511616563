.profile-page {
  margin: auto;
  height: 100%;
  width: 100%;
  max-width: 500px;
  box-sizing: border-box;
  color: var(--font-grey);
}

.profile-page-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.profile-page-body {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.profile-page-body .user-image-section {
  margin: 4px 0;
}

.profile-page-body .user-info {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.profile-page-body .profile-detail-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 500;
}

.profile-page-body .profile-detail-label {
  color: grey;
  font-size: 0.9rem;
  margin: 5px 0;
}

.profile-page-body .profile-detail-value {
  color: var(--font-grey);
}

@media screen and (max-width: 800px) {
  .profile-page {
    padding: 0 4px;
  }
}
