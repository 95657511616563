.admin-sidebar {
  width: 200px;
  padding: 12px;
  box-sizing: border-box;
  border-right: 1px solid #eee;

  ul {
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    gap: 8px;

    .list-item {
      padding: 12px;
      display: flex;
      align-items: center;
      gap: 12px;
      border-radius: var(--border-radius);
      color: var(--font-grey);
      font-size: 1rem;
      font-weight: 600;
      text-decoration: none;
      cursor: pointer;

      svg {
        width: 1.25rem;
        height: 1.25rem;
      }

      &:hover {
        background: var(--secondary-color-hover);

        svg {
          transition: transform 0.3s ease;
          transform: scale(1.1);
        }
      }
    }
  }
}
