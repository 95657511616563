.product-page {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0 32px;
  box-sizing: border-box;
}

.product-page-body {
  height: 100%;
  width: 100%;
}

.product-page-item-picture {
  width: 100%;
  height: 100%;
  max-height: 80vh;
  display: flex;
  gap: 8px;
}

.product-page-picture div {
  max-height: 400px;
  border: 1px solid #ddd;
}

.product-page .product-info {
  color: var(--font-grey);
}

.product-page .product-info h3,
.product-page .product-info h4 {
  color: var(--font-grey);
}

.product-page-item .product-page-item-details {
  padding: 0 4px;
}

.product-page-item .product-page-item-details .user-actions {
  display: flex;
  margin: 4px 0;

  .user-action-wrapper {
    padding: 8px;
    display: flex;
    align-items: center;
    gap: 6px;
    border-radius: var(--border-radius);
    background: var(--secondary-color);
    color: var(--font-grey);
    cursor: pointer;

    &:hover {
      background: var(--secondary-color-hover);
      transition: 0.2s ease-in;
    }

    .user-action-icon-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    svg {
      width: 14px;
      height: 14px;
      stroke-width: 3px;
    }

    .user-action-text {
      font-size: 1rem;
      font-weight: 600;
    }
  }

  .out-of-stock-label {
    margin-left: 6px;
    padding: 8px;
    display: flex;
    align-items: center;
    gap: 6px;
    color: #f5a623;

    svg {
      width: 14px;
      height: 12px;
      stroke-width: 3px;
    }

    .out-of-stock-text {
      font-size: 0.8rem;
      font-weight: 600;
      letter-spacing: 1px;
      text-transform: uppercase;
    }
  }
}

.product-page-item .product-page-item-details .product-info-title {
  display: flex;
  align-items: center;
  gap: 5px;
}

.product-page-item .product-page-item-details .product-info-title h3 {
  margin: 5px 0;
  font-size: 1.75rem;
}

.product-page-item .product-page-item-details .product-info-title .fill {
  fill: var(--cherry-red);
  stroke-width: 0;
}

.product-page-item .product-page-item-details .product-info-title .no-fill {
  color: grey;
}

.product-page-item
  .product-page-item-details
  .product-info-title
  .favorite-icon:hover {
  opacity: 0.7;
}

.product-page-item .product-page-item-details section {
  margin: 12px 0;
}

.product-page-item .product-page-item-details section h4 {
  margin: 6px 0 12px;
  color: var(--font-grey);
  font-size: 1.25rem;
}

.product-page-item .product-page-item-details section p {
  margin: 4px 0;
  color: var(--font-grey);
  font-size: 1.125rem;
  line-height: 1.6;
}

.product-page-item .product-page-item-details section .dimension {
  padding: 4px 8px;
  border-radius: var(--border-radius);
  font-size: 1rem;
  font-weight: 600;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: var(--font-grey);
  background: var(--secondary-color);
}

.product-page-item
  .product-page-item-details
  .product-info
  .product-info-description
  .product-info-description-text {
  padding: 4px 8px;
  border-radius: var(--border-radius);
  background: var(--secondary-color);
  font-weight: 600;
}

.product-page-item .product-info-total-price {
  margin: 24px 0;
  display: flex;
  justify-content: center;
  gap: 8px;
  color: var(--font-grey);
  font-size: 2rem;
  font-weight: 600;
}

.product-page-actions {
  margin: 24px 0;
}

.product-page-actions .counter {
  display: flex;
  justify-content: center;
  color: var(--font-grey);
}

.product-page-actions input {
  width: 70px;
  text-align: center;
  border: none;
  color: var(--font-grey);
  font-size: 1.5rem;
  font-weight: 600;
  background: inherit;
}

.product-page-actions button {
  cursor: pointer;
  padding: 4px 16px;
  font-size: 1rem;
  font-weight: 600;
  letter-spacing: 1px;
}

.product-page-actions .counter-button {
  cursor: pointer;
  padding: 4px 16px;
  background: none;
  border: none;
  font-size: 1.5rem;
  font-weight: 600;
}

.product-page-actions .counter-button:hover {
  border-radius: var(--border-radius);
  background: var(--secondary-color);
  color: var(--font-grey);
}

.product-page-actions .add-to-cart-button {
  border: none;
  color: white;
  width: 100%;
  padding: 16px;
  border: none;
  border-radius: var(--border-radius);
  background: var(--primary-color);
  font-size: 1.25rem;
  font-weight: 600;
  font-family: inherit;
}

.product-page-actions .add-to-cart-button:hover {
  background: var(--primary-color-hover);
}

.product-page-actions .add-to-cart-button-disabled {
  color: white;
  width: 100%;
  padding: 16px;
  border: none;
  border-radius: var(--border-radius);
  background: rgb(204, 204, 204);
  font-size: 1.25rem;
  font-weight: 600;
  font-family: inherit;
}

.product-page-actions .add-to-cart-button-disabled:hover {
  cursor: default;
}

.size-list {
  margin: 15px 0;
  display: flex;
  gap: 15px;
  cursor: pointer;
}

.size-list .highlight {
  width: 110px;
  height: 78px;
  border: 2px solid var(--primary-color);
  background: var(--matrix-green-light);
  color: var(--primary-color);
}

.size-list-item {
  height: 80px;
  width: 112px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 4px;
  align-items: center;
  border: 1px solid transparent;
  border-radius: var(--border-radius);
  font-weight: bold;
  background: var(--secondary-color);
}

.size-list-item:hover {
  width: 110px;
  height: 78px;
  border: 2px solid var(--primary-color);
  background: var(--matrix-green-light);
  color: var(--primary-color);
}

.size-list-item-title {
  font-size: 1.125rem;
}

.size-list-item-price {
  font-size: 1rem;
}

.product-page .suggestions-section h4 {
  color: var(--font-grey);
  text-transform: uppercase;
  font-size: 1rem;
}

.product-page .suggestions-section .suggestions-list {
  display: flex;
  gap: 8px;
}

.product-page .suggestions-section .suggestions-list a:hover {
  text-decoration: none;
}

.product-page .suggestions-section .suggestions-list .product-image-container {
  cursor: pointer;
}

.product-page .added-to-cart-notification-message {
  padding: 24px 16px;
  border-radius: var(--border-radius);
  background: white;
  color: var(--font-grey);
  box-shadow: 0 0 8px rgb(0 0 0 / 10%);
}

.product-page .item-details-header {
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.product-page .item-details-header .close-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px;
  border-radius: var(--border-radius);
  color: var(--font-grey);

  &:hover {
    background: var(--secondary-color);
    cursor: pointer;
  }

  svg {
    width: 24px;
    height: 24px;
  }
}

.product-page .added-to-cart-notification-message-body {
  display: grid;
  grid-template-rows: auto 1fr;
}

.product-page .added-to-cart-notification-message-body .img-container {
  width: 180px;
  height: 270px;
  justify-self: center;
}

.product-page .added-to-cart-notification-message-body img {
  aspect-ratio: 2 / 3;
  width: 180px;
  height: 270px;
  max-height: none;
  object-fit: cover;
}

.product-page .added-to-cart-notification-message-body .item-details {
  padding: 5px 15px;
}

.product-page .added-to-cart-notification-message h2 {
  margin: 0;
  letter-spacing: 1px;
  font-size: 1.5rem;
  font-weight: 600;
}

.product-page .added-to-cart-notification-message p {
  font-size: var(--font-body-two);
}

.product-page .added-to-cart-notification-message .buttons {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.product-page .added-to-cart-notification-message .continue-shopping-btn {
  color: var(--font-grey);
  background: var(--secondary-color);
}

.product-page .added-to-cart-notification-message .continue-shopping-btn:hover {
  background: var(--secondary-color-hover);
}

.product-page .added-to-cart-notification-message .go-to-cart-btn {
  color: #fff;
  background: var(--primary-color);
}

.product-page .added-to-cart-notification-message .go-to-cart-btn:hover {
  background: var(--primary-color-hover);
}

.product-page .gallery {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
}
.product-page .square {
  width: 96px;
  height: 96px;
  border-radius: var(--border-radius);
  background: var(--secondary-color);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  font-weight: 600;
  color: rgb(0, 0, 0, 0.1);
}

@media screen and (min-width: 801px) {
  .product-page-item {
    display: grid;
    grid-template-columns: 3fr 2fr;
    border-radius: var(--border-radius);
    gap: 24px;
  }

  .product-page .suggestions-section .suggestions-list {
    flex-direction: row;
  }
}

@media screen and (max-width: 800px) {
  .product-page {
    padding: 0 4px;
  }

  .product-page-body {
    min-height: 60vh;
  }

  .product-page-item-picture {
    flex-direction: column-reverse;
  }
  .product-page .gallery {
    flex-direction: row;
    justify-content: center;
  }
  .product-page .square {
    width: 48px;
    height: 48px;
    font-size: 1.125rem;
  }

  .product-page-item {
    display: grid;
    grid-template-rows: 1fr 2fr;
    border-radius: var(--border-radius);
    gap: 16px;
  }

  .product-page-item .product-page-item-details .product-info-title h3 {
    font-size: 1.5rem;
  }
  .product-page-item .product-page-item-details .product-info-remark p {
    font-size: 1rem;
  }
  .product-page-item .product-page-item-details section h4 {
    margin: 6px 0 18px;
    font-size: 1rem;
  }
  .product-page-item .product-page-item-details section p {
    font-size: 1rem;
  }
  .product-page-item .product-info-total-price {
    font-size: 1.625rem;
  }

  .product-page-actions input {
    font-size: 1.25rem;
  }

  .size-list-item-title {
    font-size: 1rem;
  }

  .size-list-item-price {
    font-size: 0.875rem;
  }

  .product-page .suggestions-section .suggestions-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, var(--product-height));
    justify-content: center;
  }
}
