.admin-layout {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  display: flex;

  @media only screen and (max-width: 800px) {
    > :first-child {
      display: none;
    }
  }
}

.admin-display {
  width: 100%;
  box-sizing: border-box;
  padding: 12px 24px;

  @media only screen and (max-width: 800px) {
    padding: 0px;
  }
}
