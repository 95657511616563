.application-loading-indicator {
  position: fixed;
  left: 43%;
  top: 45%;
  padding: 24px;
  display: flex;
  gap: 16px;
  border-radius: var(--border-radius);
  background: rgba(0, 0, 0, 0.45);
  color: white;
  font-size: 1.125rem;
  font-weight: 600;
  user-select: none;
}
