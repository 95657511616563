.payment-status {
  padding: 8px 16px;
  border-radius: 16px;
  font-size: 0.75rem;
  font-weight: 600;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 2px;
}

.pending-payment {
  background: rgb(255, 249, 128);
}

.paid {
  background: var(--primary-color);
  color: white;
}

@media only screen and (max-width: 800px) {
  .payment-status {
    padding: 4px 12px;
  }
}
