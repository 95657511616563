.manage-applications-page .card {
  padding: 16px;
  width: fit-content;
  min-width: 350px;
  display: grid;
  grid-template-rows: 100px;
  grid-template-columns: 80px 1fr;
  align-items: center;
  gap: 16px;
  border-radius: var(--border-radius);
  color: var(--font-grey);
}

.manage-applications-page .card .card-info {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.manage-applications-page .card .icon-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--secondary-color);
  color: rgb(220, 220, 220);
  border-radius: 9999px;
  width: 80px;
  height: 80px;
}

.manage-applications-page .card .icon-wrapper svg {
  padding: 2px;
  width: 32px;
  height: 32px;
  border-radius: 9999px;
  color: rgb(204, 205, 206);
}

.manage-applications-page .card:hover {
  text-decoration: none;
  background: var(--secondary-color);
}

.manage-applications-page .card .card-section {
  display: flex;
  align-items: center;
  gap: 5px;
}

.manage-applications-page .card .primary {
  display: flex;
  gap: 4px;
}

.manage-applications-page .card .primary .name {
  font-weight: 600;
}

.manage-applications-page .card .secondary {
  color: var(--font-light-grey);
  font-size: 0.875rem;
  font-weight: 400;
}

.manage-applications-page .card .tertiary {
  display: flex;
  gap: 4px;
}

.manage-applications-page .card .quarternary {
  display: flex;
  justify-content: space-between;
}
