.banner {
  padding: 8px 8px;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
  background: rgb(239, 185, 248);
  box-sizing: border-box;

  .message {
    color: var(--font-grey);
    font-size: 0.875rem;
    font-weight: 600;
  }

  .button {
    width: fit-content;
    padding: 6px 8px;
    border: none;
    outline: none;
    border-radius: 50px;
    background: #000;
    color: #fff;
    font-size: 0.75rem;
    font-weight: 600;
    cursor: pointer;
  }

  .button:hover {
    transform: translateY(-2px);
    transition: 0.5s ease;
  }
}

@media screen and (max-width: 800px) {
  .banner {
    .button {
      width: 5rem;
      max-width: fit-content;
    }
  }
}
