.login-document-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.login-document {
  display: flex;
  flex-direction: column;
  padding: 32px 24px;
  min-width: 200px;
  min-height: 300px;
  max-width: 500px;
  width: 80%;
  border-radius: var(--border-radius);
}

.login-document img {
  animation: none;
}

.login-document h1 {
  margin: 16px 0;
  color: var(--font-grey);
  font-family: "Prompt";
  font-weight: 800;
}

.login-document form {
  display: flex;
  flex-direction: column;
}

.login-document label {
  color: var(--font-grey);
  font-size: 1rem;
  font-weight: 600;
  margin: 8px 0 4px;
}

.login-document .field-container {
  margin: 12px 0;
}

.login-document input {
  padding: 16px;
  padding-left: 12px;
  border: rgb(229 231 235);
  border-radius: var(--border-radius);
  background-color: rgb(229 231 235);
  color: var(--font-black);
  font-size: 1rem;
  font-family: Prompt;
  font-weight: 600;
  transition: all cubic-bezier(0.4, 0, 0.2, 1) 0.5s;
  outline-color: var(--primary-color);
}

.login-document input:focus {
  background-color: rgb(243 244 246);
}

.login-document .actions {
  margin: 15px 0;
  display: flex;
  flex-direction: column;
}

.login-document button {
  background: var(--primary-color);
  color: white;
  border: none;
  border-radius: var(--border-radius);
  padding: 16px;
  font-family: inherit;
  font-size: 1.125rem;
  font-weight: bold;
  font-family: Prompt;
  letter-spacing: 1px;
  user-select: none;
}

.login-document .disabled {
  filter: brightness(95%);
}

.login-document .disabled:hover {
  opacity: 1;
  cursor: default;
}

.login-document button:hover {
  background: var(--primary-color-hover);
  cursor: pointer;
}

.login-document p {
  text-align: center;
  color: grey;
  font-size: 0.8em;
}

.nav-container {
  margin-top: 20px;
  display: flex;
  gap: 16px;
}

.login-document .tips-wrapper {
  padding: 0 4px;

  .tips {
    display: flex;
    align-items: center;
    gap: 4px;

    svg {
      color: var(--font-light-grey);
      font-size: 0.875rem;
    }

    p {
      color: var(--font-light-grey);
      text-align: left;
      font-size: 0.875rem;
    }
  }
}

.login-document .nav-btn {
  border-radius: var(--border-radius);
  padding: 16px 32px;
  font-size: 1rem;
  font-family: Prompt;
  font-weight: 600;
  text-decoration: none;
  cursor: pointer;
}

.login-document .secondary {
  color: var(--font-grey);
  background: var(--secondary-color);
}

.login-document .secondary:hover {
  background: var(--secondary-color-hover);
}

.login-document a {
  color: var(--font-grey);
}

@media screen and (max-width: 800px) {
  .login-document {
    width: 100%;
    border-radius: 0px;
    border: transparent;
  }

  .nav-container {
    flex-direction: column;
  }

  .login-document label {
    font-size: 0.875rem;
  }

  .login-document input {
    font-size: 16px; /* Prevents auto zoom from mobile devices */
  }

  .login-document .nav-btn {
    padding: 16px;
    font-size: 1rem;
    text-align: center;
  }
}
