.drop-down-menu {
  position: fixed;
  top: 0px;
  right: 0px;
  z-index: 2;
  min-height: 100vh;
  min-width: 300px;
  height: 100%;
  background: white;
  box-shadow: 0 3px 10px rgb(0 0 0 / 15%);
  transition: 0.2s ease;
}

.drop-down-menu ul {
  display: flex;
  flex-direction: column;
  background: white;
}

.drop-down-menu ul li {
  margin: auto;
  width: 90%;
  background: white;
}

.drop-down-menu ul li a {
  font-size: 1rem;
  font-weight: 600;
  padding: 12px 16px !important;
  border-radius: var(--border-radius);
}

.drop-down-menu .menu li a:hover {
  background: var(--secondary-color);
  font-weight: bold;
}

.drop-down-menu .close-button {
  padding: 24px 12px;
  text-align: right;
  cursor: pointer;
}

.drop-down-menu .close-button-icon {
  width: 30px;
  height: 30px;
  padding: 4px;
  border-radius: var(--border-radius);
  color: var(--font-grey);
}

.drop-down-menu .close-button-icon:hover {
  background: var(--secondary-color);
}

@media screen and (max-width: 1200px) {
  .drop-down-menu .close-button {
    padding: 12px 12px;
  }

  .drop-down-menu .close-button-icon {
    width: 24px;
    height: 24px;
  }
}
