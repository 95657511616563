.products-interface-wrapper .search-bar {
  display: flex;
  align-items: center;
  gap: 5px;
}

.products-interface-wrapper .search-bar input {
  padding: 4px;
  border-radius: 24px;
  border: 1px solid #ddd;
  letter-spacing: 1px;
  font-size: 0.875rem;
  font-weight: 400;
  transition: 0.6s ease;
}

.products-interface-wrapper .search-bar svg {
  height: 18px;
  width: 18px;
  color: #aaa;
  cursor: pointer;
}

@media only screen and (max-width: 800px) {
  .search-bar-wrapper {
    padding: 0 12px;
  }
}
