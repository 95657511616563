.business-registration-upload-forms-page {
  height: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.business-registration-upload-forms-page .upload-list {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  gap: 5px;
}

.business-registration-upload-forms-page hr {
  margin: 20px 0;
}

.business-registration-upload-forms-page .upload-component h3 {
  margin: 4px 0;
}

.business-registration-upload-forms-page .upload-component {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  box-sizing: border-box;
}

.business-registration-upload-forms-page .upload-component .file-details {
  display: flex;
  flex-direction: column;
  gap: 8px;
  font-size: 1rem;
  color: var(--font-grey);
  font-weight: 400;
  line-height: 1.6;
}

.business-registration-upload-forms-page .upload-component .file-actions {
  margin-top: 8px;
  display: flex;
  align-items: center;
  gap: 16px;
  font-weight: 400;
}

.business-registration-upload-forms-page
  .upload-component
  .file-actions
  button {
  background: var(--secondary-color-hover);
}

.business-registration-upload-forms-page
  .upload-component
  .file-actions
  div:hover {
  text-decoration: none;
  cursor: pointer;
}
