.field {
  display: grid;
  grid-template-columns: 160px 1fr;

  label {
    padding: 12px 16px;
    font-weight: 600;
  }

  input {
    padding: 12px 16px;
    border: 1px solid white;
    border-radius: var(--border-radius);
    background: white;
    color: var(--font-grey);
    font-size: 1rem;
    font-family: inherit;
    font-weight: 600;
  }
}
