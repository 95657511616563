.categories-page {
  height: 100%;
  width: 100%;
}

.categories-page-header {
  position: relative;
  height: auto;
  width: 100%;
}

.categories-page-header img {
  height: 240px;
  width: 100%;
  object-fit: cover;
  filter: brightness(0.75);
}

.categories-page-header h1 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  letter-spacing: 10px;
  font-weight: 400;
  text-transform: uppercase;
}

.categories-page-body {
  padding: 16px 32px;
}

.categories-page .categories-list {
  display: flex;
  flex-direction: column;
  gap: 25px;
}

.categories-page .categories-list .categories-list-alphabet {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.categories-page .categories-list .categories-list-alphabet h3 {
  margin: 0px;
}

.categories-page
  .categories-list
  .categories-list-alphabet
  .categories-list-alphabet-items {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, 200px);
}

.categories-page
  .categories-list
  .categories-list-alphabet
  .categories-list-alphabet-items
  a {
  color: inherit;
}
