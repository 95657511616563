.landing-page {
  background: white;

  h2 {
    font-size: 3rem;
    line-height: 1.4;
    font-weight: bold;
  }

  .section {
    display: grid;
    grid-template-rows: 1fr;
    align-items: center;
    justify-items: center;
    padding: 10vh 0;
  }

  .title {
    color: var(--font-grey);
    text-align: left;
  }

  .paragraph {
    margin: auto;
    color: var(--font-grey);
    font-size: 1.375rem;
    line-height: 1.75;
    max-inline-size: 66ch;
    text-align: left;
  }

  section,
  .product {
    color: var(--font-grey);
  }

  .product .product-image-container {
    height: var(--public-image-height);
    width: var(--public-image-height);
  }

  .product .product-image-container .product-image {
    max-height: var(--public-image-height);
  }

  @media only screen and (max-width: 800px) {
    h2 {
      font-size: 1.625rem;
    }

    .paragraph {
      margin: auto;
      color: var(--font-grey);
      font-size: 1.375rem;
      line-height: 1.75;
      max-inline-size: 66ch;
      text-align: left;
    }
  }
}
