.order-page {
  margin: auto;
  height: 100%;
  width: 100%;
  max-width: var(--desktop-page-limit);
  box-sizing: border-box;
  color: var(--font-grey);
  animation: fadeIn 0.6s;
}

.order-page-header {
  display: flex;
  flex-direction: column;
}

.order-page-header h1 {
  font-size: 1.75rem;
}

.order-page-header-title {
  margin-top: 0px;
  display: inline-block;
  color: var(--font-grey);
}

.order-page-header .view-invoice-btn {
  display: flex;
  align-items: center;
  gap: 8px;
}

.order-page-header .receipt-icon {
  width: 1.125rem;
  height: 1.125rem;
}

.order-page-header .receipt-icon svg {
  width: 100%;
  height: 100%;
}

.order-page-header .view-invoice-btn:hover {
  background: var(--secondary-color-hover);
}

.order-page .order-details-title {
  display: flex;
  align-items: center;
  gap: 16px;
}

.order-page .order-details-title h2 {
  font-size: 1.25rem;
}

.order-page-body {
  display: flex;
  flex-direction: column;
}

.order-page-body .pay-button {
  padding: 4px 12px;
  display: flex;
  align-items: center;
  gap: 4px;
  border-radius: var(--border-radius);
  color: var(--font-grey);
  background: var(--secondary-color);
  font-size: 0.9375rem;
  font-weight: 600;
  cursor: pointer;
}

.order-page-body .pay-button .pay-icon {
  width: 16px;
  height: 16px;
}

.order-page-body .pay-button:hover {
  opacity: 0.8;
}

.order-page-body .divider {
  margin: 24px 0;
  width: 100%;
  border: none;
  border-top: 1px solid #eee;
}

.payment-summary {
  display: flex;
  flex-direction: column;
  gap: 8px;

  h2,
  h3 {
    margin: 0;
  }
}

.transaction-details,
.card-details,
.order-details {
  padding: 16px 0px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  border-radius: var(--border-radius);

  p {
    margin: 0px;
  }
}

.transaction-details {
  line-height: 1.75;
  .transaction-success {
    border-radius: var(--border-radius);
    font-size: 0.75rem;
    font-weight: 600;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: var(--primary-color);
    width: fit-content;
  }
}

@media screen and (max-width: 800px) {
  .order-page {
    padding: 0 4px;
  }
  .order-page-header h1 {
    font-size: 1.5rem;
  }
}
