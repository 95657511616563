.home-page {
  height: 100%;
  width: 100%;
  --thumbnail-width: 450px;
}

.home-page-body {
  height: 100%;
  width: 100%;
  color: var(--font-grey);
}

.home-page h2 {
  margin: 0;
  font-size: 1.5rem;
}

.home-page .category-list {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 0 32px;
  box-sizing: border-box;
}

.home-page .categories-section {
  overflow-x: scroll;
}
.home-page .shop-panel {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.home-page .shop-panel section {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.home-page .shop-panel .list {
  margin: 0;
  display: flex;
  gap: 16px;
  padding: 0;
  overflow-x: scroll;
}

.home-page .shop-panel .thumbnail {
  position: relative;
  width: var(--thumbnail-width);
  height: 300px;
  border-radius: 5px;
  overflow: hidden;
  cursor: pointer;
}

.home-page .shop-panel .thumbnail .responsive-image {
  height: auto;
  max-width: 100%;
  object-fit: cover;
  filter: brightness(65%);
}

.home-page .shop-panel .thumbnail .responsive-image:hover {
  filter: brightness(35%);
  transition: 0.5s ease-in;
}

.home-page .shop-panel .thumbnail-text {
  position: absolute;
  bottom: 15px;
  right: 25px;
  color: white;
  font-size: var(--font-subheading-two);
  font-weight: 600;
  text-transform: uppercase;
}

.home-page .shop-panel .hot-items-list {
  display: flex;
  gap: 8px;
}

.home-page .shop-panel .hot-items-list a:hover {
  text-decoration: none;
}

.home-page .shop-panel .hot-items-list .product-image-container {
  cursor: pointer;
}

.home-page .shop-panel .brands-section .thumbnail .responsive-image {
  filter: brightness(95%);
}

.home-page .shop-panel .brands-section .thumbnail .responsive-image:hover {
  filter: brightness(60%);
}

@media only screen and (max-width: 1200px) {
  .home-page {
    --thumbnail-width: 300px;
  }

  .home-page .category-list {
    padding: 0 4px;
  }

  .home-page .shop-panel .thumbnail {
    width: 100%;
    height: 100%;
  }

  .home-page .shop-panel .thumbnail img {
    width: 100%;
    height: 100%;
  }

  .home-page .shop-panel .list {
    height: auto;
    display: grid;
    grid-template-columns: 1fr;
    gap: 4px;
    overflow: hidden;
  }

  .home-page .shop-panel .hot-items-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, var(--product-height));
    gap: 8px;
  }
}
