.checkout-summary {
  margin-top: 12px;
  padding: 32px 16px;
}

.checkout-summary div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 1.2em;
}

.checkout-summary hr {
  border: none;
  border-top: 1px solid #eee;
  margin: 25px 0;
}

.checkout-summary .primary {
  font-size: 1.1875rem;
  font-weight: bold;
}

.checkout-summary .misc .secondary {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 1rem;
  color: var(--font-light-grey);
}

.checkout-summary .subtotal {
  font-size: 1.125rem;
}

.checkout-summary .misc {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 4px;
}

.checkout-summary .total-due {
  font-weight: 400;
}

.checkout-summary .payment-section {
  width: 100%;
  margin: 36px auto;
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.checkout-summary .payment-button {
  width: 100%;
  padding: 16px 24px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  border: none;
  border-radius: var(--border-radius);
  font-family: inherit;
  color: var(--font-grey);
  user-select: none;
  cursor: pointer;
}
.checkout-summary .primary-btn {
  background: var(--primary-color);
  color: white;
}
.checkout-summary .primary-btn:hover {
  background: var(--primary-color-hover);
}
.checkout-summary .secondary-btn {
  color: var(--font-grey);
  background: var(--secondary-color);
}
.checkout-summary .secondary-btn:hover {
  background: var(--secondary-color-hover);
}
.checkout-summary .loading-btn {
  flex-direction: column;
}
.checkout-summary .payment-button .payment-button-icon svg {
  width: 28px;
  height: 28px;
}
.checkout-summary .payment-button .payment-button-info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.checkout-summary .payment-button-title {
  margin: 0;
  font-size: 1.1875rem;
  font-weight: 700;
}
.checkout-summary .payment-button-description {
  margin: 5px 0;
  padding: 0px 16px;
  font-size: 0.875rem;
  font-weight: 600;
}

.checkout-summary .price-divider {
  margin: 24px 0;
  width: 100%;
  border: none;
  border-top: 1px solid #eee;
}
.checkout-summary .divider {
  margin: 8px 0;
  width: 100%;
  border: none;
  border-top: 1px solid #eee;
}

@media screen and (max-width: 800px) {
  .checkout-summary {
    padding: 0px 4px;
  }

  .checkout-summary .subtotal {
    font-size: 1.125rem;
  }

  .checkout-summary .primary {
    font-size: 1.1875rem;
  }
}
