.statement-of-accounts-page {
  height: 100%;
  width: 100%;
  padding: 0 32px;
  box-sizing: border-box;
}

.statement-of-accounts-page .list {
  display: flex;
  flex-direction: column;
}

.statement-of-accounts-page .list .year {
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 4px 0;
}

.statement-of-accounts-page .list .year h2 {
  margin: 0;
}

.statement-of-accounts-page .list .year hr {
  border: none;
  border-top: 1px solid #ccc;
  margin: 24px 0;
}

.statement-of-accounts-page .list .year .months {
  display: flex;
  flex-direction: column;
  gap: 16px 0;
}

@media screen and (max-width: 800px) {
  .statement-of-accounts-page {
    padding: 0 4px;
  }
}
