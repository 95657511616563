.landing-page-layout {
  padding-top: var(--header-height);

  .button {
    padding: 20px 48px;
    border: none;
    border-radius: var(--border-radius);
    color: white;
    font-family: inherit;
    font-size: 1.125rem;
    font-weight: 800;
    letter-spacing: 2px;
    background-color: var(--primary-color);
  }

  .button:hover {
    text-decoration: none;
    background-color: var(--primary-color-hover);
    transition: 0.1s ease;
    cursor: pointer;
  }

  a {
    color: inherit;
  }

  @media only screen and (max-width: 800px) {
    .button {
      font-size: 1.125rem;
    }
  }
}
