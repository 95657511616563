.carousel-item {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  background: black;
}

.carousel-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  filter: brightness(65%);
  user-select: none;
  animation: slide 0.15s ease-in;
}

.carousel-item .overlay {
  width: 100%;
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.carousel-item .overlay .content {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.carousel-item .overlay .content p {
  margin: 0;
  color: #fff;
  font-size: 3.5rem;
  font-weight: 800;
  font-family: Prompt;
  text-align: center;
  user-select: none;
}

.carousel-item .buttons {
  margin-top: 16px;
  display: flex;
  justify-content: center;
  gap: 8px;
}

.carousel-item .btn {
  padding: 16px 32px;
  border-radius: var(--border-radius);
  font-size: 1rem;
  background: var(--secondary-color);
  color: var(--font-grey);
  font-family: Prompt;
  font-weight: 600;
  text-decoration: none;
  cursor: pointer;
  transition: 0.2s ease-in;
}

.carousel-item .btn:hover {
  transform: translateY(-4px);
}

.carousel-item .secondary-btn {
  background: none;
  color: #fff;
}

@media screen and (max-width: 800px) {
  .carousel-item .overlay {
    top: 55%;
  }

  .carousel-item .overlay .content p {
    font-size: 2.5rem;
  }
}
