.picture-grid {
  position: relative;
  height: auto;
  width: 100%;
  padding: 25px;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: repeat(auto-fill, var(--product-height));
  gap: 24px 8px;
}

.picture-grid a:hover {
  text-decoration: none;
}

@media only screen and (max-width: 800px) {
  .picture-grid {
    padding: 8px;
    justify-content: center;
  }
}
