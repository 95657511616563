.manage-products-page {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}

@media only screen and (max-width: 800px) {
  .manage-products-page {
    padding: 0 4px;
  }

  .manage-products-page .breadcrumbs {
    padding: 0 16px;
  }
}
