.add-address-page {
  margin: auto;
  max-width: 799px;

  hr {
    width: 100%;
    border: none;
  }
}

.fields {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.field-group {
  padding: 24px;
  box-sizing: border-box;
  border-radius: var(--border-radius);
  background: var(--secondary-color);

  h3 {
    margin: 0;
    margin-bottom: 18px;
  }
}

.field {
  display: grid;
  grid-template-columns: 160px 1fr;

  label {
    padding: 12px 16px;
    font-weight: 600;
  }

  input {
    padding: 12px 16px;
    border: 1px solid white;
    border-radius: var(--border-radius);
    background: white;
    color: var(--font-grey);
    font-size: 1rem;
    font-family: inherit;
    font-weight: 600;
  }

  .required-field::before {
    content: "* "; /* Space after the asterisk is intentional to improve visual spacing and appearance of the label. */
    color: red;
    font-size: 0.75rem;
    vertical-align: text-top;
  }
}

.button-list {
  margin-top: 24px;
  display: flex;
  gap: 8px;
}

.two-column {
  display: grid;
  grid-template-columns: 1fr 1fr;

  @media screen and (max-width: 800px) {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
}
