.navigation-bar {
  margin-top: 24px;

  ul {
    width: fit-content;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    gap: 8px;
    list-style-type: none;

    li {
      &.highlight a {
        background: var(--secondary-color);
        font-weight: 600;
      }
    }

    li a {
      padding: 12px 16px;
      display: flex;
      align-items: center;
      gap: 8px;
      border-radius: var(--border-radius);
      font-size: 1.125rem;
      color: var(--font-grey);
      text-decoration: none;

      &:hover {
        background: var(--secondary-color);
      }
    }
  }
}
