.business-form {
  padding: 0 12px;
  display: flex;
  flex-direction: column;
  gap: 16px;

  font-weight: 400;

  button {
    background: var(--secondary-color-hover);
  }
}
