.application-layout-body {
  height: 100%;
  width: 100%;
  padding: 0 32px;
  margin: auto;
  max-width: 1360px;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 300px auto;
  color: var(--font-grey);
}

.application-layout-body > * {
  animation: fadeIn 1s;
}

.application-layout-body .registration-page {
  display: flex;
  flex-direction: column;
  max-width: 928px;
}

.application-layout-body blockquote {
  margin: 0;
  padding: 24px;
  border-radius: var(--border-radius);
  background: var(--secondary-color);
  line-height: 1.6;
  min-inline-size: 30ch;
}

.application-layout-body .field-group {
  display: flex;
  flex-direction: column;
}

.application-layout-body .field-list {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.application-layout-body .field:nth-child(1) {
  margin-top: 0px;
}

.application-layout-body .field {
  display: grid;
  grid-template-columns: 160px 1fr;
  align-items: center;
}

/* .application-layout-body section {
  padding: 24px;
  box-sizing: border-box;
  border-radius: 16px;
  background: var(--secondary-color);

  input {
    background: white;
  }
} */

.field label {
  padding: 12px 16px;
  font-weight: 600;
}

.field input,
select {
  padding: 12px 16px;
  border: 1px solid white;
  border-radius: var(--border-radius);
  background: white;
  color: var(--font-grey);
  font-size: 1rem;
  font-weight: 600;
  font-family: inherit;

  &:disabled {
    background: rgb(246, 246, 246);
  }
}

.field input::placeholder {
  color: rgb(160, 160, 160);
  font-weight: 400;
}

.application-layout-body .form-footer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 200px;
}

.application-layout-body .form-footer button:nth-child(1) {
  background: var(--secondary-color);
  color: var(--font-grey);

  &:hover {
    background: var(--secondary-color-hover);
  }
}

.application-layout-body .form-footer button:nth-child(2) {
  &:hover {
    background: var(--primary-color-hover);
  }
}

.application-layout-body .form-footer button {
  padding: 16px 32px;
  border-radius: var(--border-radius);
  border: none;
  background: var(--primary-color);
  color: white;
  font-size: 1.125rem;
  font-family: inherit;
  font-weight: 600;
  cursor: pointer;
}

.application-layout-body .form-footer button:hover {
  transition: 0.1s ease;
  cursor: pointer;
}

.application-layout-body hr {
  width: 100%;
  margin: 12px 0;
  border: none;
}

.application-layout-body .legal-page {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.application-layout-body .legal-page blockquote p {
  margin: 8px 0;
}

.application-layout-body fieldset {
  border: none;
  padding: 24px;
}
.application-layout-body fieldset legend {
  font-size: 1rem;
  font-weight: 600;
  display: none;
}

.application-layout-body fieldset input {
  width: 16px;
  height: 16px;
}
.application-layout-body fieldset label {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 1rem;
}
.application-layout-body {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
  }
}
