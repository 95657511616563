.hero {
  width: 100vw;
  height: 100vh;

  .poster {
    position: relative;
    width: 100vw;
    height: 100vh;
  }

  .poster img {
    width: 100vw;
    height: 100vh;
    object-fit: cover;
    filter: brightness(0.65);
  }

  .poster .overlay {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }

  .poster .overlay .content {
    position: absolute;
    top: 10%;
    left: 32%;
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  .poster .overlay .text-block {
    margin-top: 108px;
    padding: 0 48px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: white;
    letter-spacing: 2px;
    text-align: center;
    font-weight: 600;
  }

  .poster .overlay .text-block h2 {
    margin: 0;
    font-size: 4rem;
    font-weight: 400;
    line-height: 0.5;
    font-family: Lato;
    text-align: left;
  }

  .poster .overlay .text-block p {
    margin: 0;
    margin-top: 36px;
    margin-left: 24px;
    text-align: left;
    font-size: 1.125rem;
    font-weight: 400;
    font-family: Lato;
  }

  .poster .overlay .see-more-btn button {
    background: var(--secondary-color);
    color: var(--font-grey);
    font-weight: 900;

    &:hover {
      background: var(--secondary-color-hover);
    }
  }

  .action-buttons {
    margin-top: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
  }

  .action-buttons .tertiary-btn {
    background: var(--secondary-color);
    color: var(--font-grey);
  }

  .action-buttons .tertiary-btn:hover {
    background: var(--secondary-color-hover);
  }

  @media only screen and (max-width: 800px) {
    .poster .overlay .text-block {
      margin-top: 96px;
      padding: 0 16px;
    }

    .poster .overlay .text-block h2 {
      font-size: 4rem;
      letter-spacing: 0.5px;
      line-height: 1;
    }

    .poster .overlay .text-block p {
      margin-top: 12px;
      margin-left: 8px;
      font-size: 1rem;
      letter-spacing: 0.5px;
    }

    .poster .overlay .see-more-btn {
      width: fit-content;
      bottom: 25%;
    }

    .action-buttons {
      margin-top: 24px;
      flex-direction: column;
      gap: 8px;
    }

    .action-buttons .button {
      padding: 24px 0;
      width: 280px;
      margin: 0;
      text-align: center;
    }
  }
}
