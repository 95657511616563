.catalogue-page {
  .food-list {
    margin: 8px 0;
    display: grid;
    grid-template-columns: repeat(3, var(--public-image-height));
    justify-content: center;
    gap: 8px;

    .product-image-container {
      height: var(--public-image-height);
      width: var(--public-image-height);

      .product-image {
        max-height: var(--public-image-height);
      }
    }
  }

  .button {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  a:hover {
    text-decoration: none;
  }

  @media only screen and (max-width: 800px) {
    .food-list {
      grid-template-columns: repeat(1, var(--public-image-height));
    }
  }
}
