.page-title {
  margin: 24px 0;
  display: flex;
  align-items: center;
  gap: 12px;

  svg {
    width: 1.75rem;
    height: 1.75rem;
  }

  h1 {
    font-size: 2rem;
  }
}
