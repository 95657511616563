.breadcrumbs {
  margin: 8px 0;
  color: var(--font-grey);
  font-size: 0.75rem;
  font-weight: 600;
}

.breadcrumbs .delimiter {
  margin: 0 5px;
}

.breadcrumbs a {
  color: var(--primary-color);
}
