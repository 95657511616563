.manage-applications-page .list {
  display: none;
  flex-direction: column;
  gap: 8px;
  border-radius: var(--border-radius);
}

.manage-applications-page hr {
  margin: 0;
  width: 100%;
  border: none;
  border-top: 1px solid rgb(246, 246, 246);
}

@media screen and (max-width: 800px) {
  .manage-applications-page .list {
    display: flex;
  }
}
