.analytics-widget {
  padding: 16px;
  width: 320px;
  height: 136px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  box-sizing: border-box;
  background: var(--secondary-color);
  border-radius: var(--border-radius);

  &.red {
    background: rgb(252, 234, 238);
  }

  &.blue {
    background: rgb(238, 242, 255);
  }

  .header {
    margin: 16px 0;
    display: flex;
    align-items: center;
    gap: 8px;

    .title {
      font-weight: 600;
    }

    .date-range {
      margin-top: 2px;
      font-size: 0.75rem;
      font-weight: 600;
      color: var(--font-light-grey);
    }
  }

  .description {
    font-weight: 600;
    font-size: 1.5rem;
  }
}

@media screen and (max-width: 800px) {
  .analytics-widget {
    height: 144px;
    padding: 32px;

    .header {
      margin-bottom: 0px;
    }
  }
}
