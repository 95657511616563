.page {
  min-height: 30vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 50px;
  color: var(--font-grey);
  line-height: 1.4;

  .page-header {
    position: relative;
    height: 100%;
    width: 100%;
  }

  h1 {
    width: 100%;
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    font-size: 3.5rem;
    font-weight: 400;
    text-align: center;
  }

  .page-header img {
    height: 480px;
    width: 100%;
    object-fit: cover;
    filter: brightness(45%);
  }

  h2 {
    font-size: 1.925rem;
    font-weight: 400;
  }

  h3 {
    font-size: 1.375rem;
  }

  h4 {
    font-size: 1.25rem;
  }

  section {
    width: 100%;
    padding: 0 10vw;
    box-sizing: border-box;
  }

  ul li {
    margin-top: 8px;
    font-size: 1rem;
    line-height: 1.75;
  }

  p {
    font-size: 1rem;
    line-height: 1.8;
    max-inline-size: 80ch;
  }

  @media only screen and (max-width: 800px) {
    h1 {
      width: 90%;
      text-align: center;
      font-size: 3rem;
      line-height: 1.8;
    }

    h2 {
      font-size: 2rem;
    }

    h3 {
      font-size: 1.5rem;
    }

    h4 {
      font-size: 1.25rem;
    }

    p {
      font-size: 1.125rem;
    }
  }
}
