.title {
  display: flex;
  align-items: center;
  gap: 8px;

  svg {
    width: 1.125rem;
    height: 1.125rem;
  }
}
