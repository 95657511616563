.delivery-details {
  .delivery-description {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  .delivery-option-title {
    margin-bottom: 0px;
  }

  .no-addresses-section {
    display: flex;
    gap: 12px;

    .text-container {
      margin: auto;
      display: flex;
      flex-direction: column;

      blockquote {
        padding: 16px 24px;
        margin: 8px;
        border-radius: 8px;
        background: rgb(247 208 77 / 47%);
      }

      p {
        font-size: 1rem;
        color: #4f4a4a;
      }
    }
  }
}
