.update-password-page {
  height: 100%;
  width: 100%;
}

.update-password-page input {
  border: 1px solid rgba(0, 0, 0, 0.08);
  padding: 5px;
  margin: 5px 0;
  font-size: 1.2em;
}

.update-password-page label {
  font-size: var(--font-body-two);
}

.update-password-page button {
  background: var(--primary-color);
  color: white;
  border: none;
  border-radius: 24px;
  margin: 24px 0;
  padding: 8px 20px;
  font-size: 1rem;
  font-weight: bold;
  letter-spacing: 1px;
  cursor: pointer;
}

.update-password-page button:hover {
  opacity: 0.9;
}

.update-password-page .notification {
  display: flex;
  flex-direction: column;
  border-radius: var(--border-radius);
}

.update-password-page .notification .message {
  padding: 15px 0px;
}

.update-password-page h4 {
  text-transform: uppercase;
  letter-spacing: 2px;
  color: var(--primary-color);
}

.update-password-page .time {
  font-size: var(--font-body-two);
  color: grey;
  text-transform: uppercase;
}

.update-password-page .back-to-home-btn {
  margin: 25px 0;
}
