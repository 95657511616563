.public-auth-page {
  height: 100%;
  background: rgb(254, 254, 254);

  .public-auth-page-body {
    height: 100%;
    display: grid;
    grid-template-columns: 2fr 3fr;
  }

  .public-auth-page-body .poster {
    filter: brightness(95%);
  }

  @media only screen and (max-width: 800px) {
    .public-auth-page-body {
      grid-template-columns: 1fr;
    }

    .public-auth-page-body .poster {
      display: none;
    }
  }
}
