.empty-cart-message {
  width: 100%;
  height: 40vh;
  padding: 32px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
}

.empty-cart-message .empty-cart-content {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.empty-cart-message .cart-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--font-light-grey);
  transform: scaleX(-1);
}
.empty-cart-message .cart-icon svg {
  width: 96px;
  height: 96px;
}
.empty-cart-message p {
  margin: 0;
  color: var(--font-grey);
  font-size: 1.125rem;
  font-weight: 600;
  line-height: 1.6;
}
.empty-cart-message p:nth-child(2) {
  font-size: 1.125rem;
}
.empty-cart-message a {
  width: fit-content;
  padding: 16px 32px;
  background: var(--secondary-color);
  color: var(--font-grey);
  font-weight: 600;
  font-size: 1.125rem;
  border-radius: var(--border-radius);
  text-decoration: none;
}
.empty-cart-message a:hover {
  text-decoration: none;
  background: var(--secondary-color-hover);
  transition: 0.2s ease-in;
}
@media screen and (max-width: 800px) {
  .empty-cart-message {
    padding: 24px 0px 48px;
    height: 20vh;
  }
  .empty-cart-message .cart-icon svg {
    width: 80px;
    height: 80px;
  }
}
