.splash-page {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: var(--secondary-color);
  color: var(--primary-color);
  animation: fadeIn 0.6s;
}

@media screen and (max-width: 1200px) {
  .splash-page .logo {
    height: 108px;
  }
}

@media screen and (min-width: 1200px) {
  .splash-page .logo {
    height: 144px;
  }
}
