.statement-of-account-page {
  height: 100%;
  width: 100%;
  padding: 0 32px;
  box-sizing: border-box;
}

.statement-of-account-page table {
  width: 100%;
  margin: 25px 0;
  text-align: left;
  border-collapse: collapse;
}

.statement-of-account-page thead tr {
  font-weight: bold;
}

.statement-of-account-page table tr {
  border: 1px solid black;
}

.statement-of-account-page hr {
  margin: 25px 0;
  border: none;
  border: 2px solid black;
}

@media screen and (max-width: 800px) {
  .statement-of-account-page {
    padding: 0 4px;
  }
}
