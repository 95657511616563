.form-footer {
  margin: 64px 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media only screen and (max-width: 800px) {
    margin: 24px 0;
  }
}
