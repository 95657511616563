.analytics-section {
  h2 {
    color: var(--font-grey);
    font-size: 1.125rem;
  }

  .list {
    display: flex;
    gap: 16px;

    a {
      user-select: none;

      &:hover {
        text-decoration: none;
      }
    }
  }
}

@media screen and (max-width: 800px) {
  .analytics-section {
    margin: auto;
    max-width: 80vw;

    .list {
      flex-direction: column;
      align-items: center;
    }
  }
}
