.cart-page {
  height: 100%;
  width: 100%;
  color: var(--font-grey);
  padding: 0 32px;
  box-sizing: border-box;
}

.cart-page-header h1 {
  font-size: 1.75rem;
}

.cart-page .estimated-total {
  font-weight: 400;
}

.cart-page .go-to-checkout-button {
  width: 100%;
  background: var(--primary-color);
  border: none;
  color: white;
  padding: 16px;
  border-radius: var(--border-radius);
  font-size: 1.125rem;
  font-weight: bold;
  letter-spacing: 1px;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
}

.cart-page .go-to-checkout-button a {
  color: white;
  text-decoration: none;
}

.cart-page .go-to-checkout-button:hover {
  background: var(--primary-color-hover);
}

.cart-summary {
  margin-top: 8px;
  padding: 32px 16px;
}

.cart-summary div {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.cart-summary .primary {
  font-weight: bold;
  font-size: 1.1875rem;
}

.cart-summary .secondary {
  margin: 15px 0;
  color: var(--font-light-grey);
  font-size: 1rem;
  line-height: 1.4;
}

@media screen and (min-width: 800px) {
  .cart-page-body {
    min-height: 60vh;
    display: grid;
    grid-template-columns: 3fr 2fr;
    grid-gap: 15px;
  }
}

@media screen and (max-width: 800px) {
  .cart-page {
    padding: 0 4px;
  }
  .cart-page-header {
    padding: 0 5px;
  }
  .cart-page-header h1 {
    font-size: 1.5rem;
  }
  .cart-page-body {
    min-height: 60vh;
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  .cart-summary {
    padding: 0px 4px;
  }
}
