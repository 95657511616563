.manage-applications-page .table-wrapper {
  padding: 12px 16px;
  border-radius: var(--border-radius);
  box-sizing: border-box;
  background: white;
  border: 1px solid rgb(246, 246, 246);
}
.manage-applications-page .table {
  width: 100%;
  border-collapse: collapse;
  color: var(--font-grey);
  --cell-padding: 16px;
}

.manage-applications-page .table th {
  padding: 16px var(--cell-padding);
  font-size: 1.125rem;
  font-size: 1rem;
  text-align: left;
  cursor: default;
  border-bottom: 1px solid rgb(246, 246, 246);

  &:last-child {
    text-align: center;
  }
}

.manage-applications-page .table tbody tr {
  border-radius: var(--border-radius);
  cursor: pointer;
  border-bottom: 1px solid rgb(246, 246, 246);

  &:hover {
    background: rgb(250, 250, 250);
  }

  &:last-child {
    border-bottom: none;
  }
}

.manage-applications-page .table td {
  padding: var(--cell-padding);

  &:first-child {
    border-radius: var(--border-radius) 0 0 var(--border-radius);
  }

  &:last-child {
    border-radius: 0 var(--border-radius) var(--border-radius) 0;
  }
}

.manage-applications-page .table .seq {
  color: var(--font-light-grey);
  font-size: 0.6375rem;
  font-weight: 600;
}

.manage-applications-page .table .status {
  font-weight: 600;
  font-size: 0.75rem;
  color: grey;

  &.approved {
    color: green;
  }
}

@media screen and (max-width: 800px) {
  .manage-applications-page .table-wrapper {
    display: none;
  }
}
