.contact-us-section {
  min-height: 60vh;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  .contact-us-details .title {
    font-size: 2.5rem;
    margin-bottom: 32px;
    text-align: center;
  }

  .contact-us-details .paragraph {
    font-size: 1.125rem;
    text-align: center;
  }

  .action-buttons {
    margin-top: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
  }

  .action-buttons .tertiary-btn {
    background: var(--secondary-color);
    color: var(--font-grey);
  }

  .action-buttons .tertiary-btn:hover {
    background: var(--secondary-color-hover);
  }

  @media only screen and (max-width: 800px) {
    min-height: 80vh;
    padding: 0 24px;

    .contact-us-details .title {
      margin-bottom: 24px;
      font-size: 2.5rem;
    }

    .contact-us-details .paragraph {
      font-size: 1.25rem;
    }

    .action-buttons {
      margin-top: 16px;
      flex-direction: column;
      gap: 8px;
    }

    .action-buttons .button {
      padding: 24px 0;
      width: 100%;
      margin: 0;
    }
  }
}
