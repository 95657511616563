.print-invoice-page {
  padding: 0 10vw;
  font-size: 0.8rem;
  animation: fadeIn 0.6s;
}

.print-invoice-page .buttons {
  display: flex;
  gap: 8px;
}

.print-invoice-page .buttons > * {
  border: none;
  border-radius: 24px;
}

.print-invoice-page .order-details-summary .primary {
  font-weight: bold;
}

@media print {
  .print-invoice-page {
    padding: initial;
  }

  .print-invoice-page .no-print {
    display: none !important;
  }
}
