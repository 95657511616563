.product-picture-with-upload {
  position: relative;
  width: 100%;
  height: 100%;
  flex: 1;
  border-radius: 5px;
}

.product-picture-with-upload .product-picture {
  width: 100%;
  height: 100%;
  border-radius: var(--border-radius);
  object-fit: contain;
  cursor: pointer;
}

.product-picture-hover:hover {
  .button .button-text {
    visibility: visible;
    border-radius: var(--border-radius);
    background: var(--matrix-green);
    transition: 0.5s ease-in;
  }
}

.product-picture-with-upload .button {
  width: 100%;
  height: 100%;
  display: block;
  cursor: pointer;
}

.product-picture-with-upload .upload-controls {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.product-picture-with-upload .button .button-text {
  visibility: hidden;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.product-picture-with-upload .button .button-text span {
  padding: 12px 16px;
  background: white;
  color: var(--font-grey);
  font-size: 1.25rem;
  font-weight: 600;
  border-radius: var(--border-radius);
}

.product-picture-with-upload .input {
  visibility: hidden;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.product-picture-with-upload .transition-wrapper {
  height: auto;
}
