.notification {
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 4px;
  border-radius: var(--border-radius);
  cursor: pointer;

  &:hover {
    transition: 0.5s ease;
    background: rgb(246, 246, 246);
  }

  .title {
    color: var(--font-grey);
    font-weight: 600;
  }

  .info {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;

    .description {
      color: var(--font-light-grey);
      font-size: 0.875rem;
    }
    .time {
      color: var(--font-light-grey);
      font-size: 0.75rem;
    }
  }
}
