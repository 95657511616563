.addresses-page {
  margin: auto;
  max-width: 450px;
  padding: 24px;

  .header {
    display: flex;
    align-items: center;
    gap: 8px;

    h1 {
      font-size: 1.75rem;
    }

    .add-new-address-btn {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 8px;

      svg {
        width: 16px;
        height: 16px;
      }
    }
  }

  .address-list {
    max-width: 500px;
    display: flex;
    flex-direction: column;
    gap: 8px;

    .address-card {
      padding: 0 12px;
      display: flex;
      flex-direction: column;
      gap: 8px;

      .toolbar {
        padding: 8px 0;
        display: flex;
        gap: 8px;

        span {
          padding: 8px;
          border-radius: var(--border-radius);
          background: var(--secondary-color);
          font-size: 0.875rem;
          font-weight: 600;
          cursor: pointer;

          &:hover {
            background: var(--secondary-color-hover);
          }
        }
      }
    }
  }
}

.divider {
  width: 100%;
  border: none;
  border-top: 1px solid var(--secondary-color);
}

.nav-bar {
  margin-top: 24px;
  padding: 24px 0;
  display: flex;

  .back-btn {
    padding: 8px;
    border-radius: var(--border-radius);
    background: var(--secondary-color);
    font-size: 0.875rem;
    font-weight: 600;
    cursor: pointer;

    &:hover {
      background: var(--secondary-color-hover);
    }
  }
}
