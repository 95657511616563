.review-field {
  display: grid;
  grid-template-columns: 160px 1fr;
  align-items: center;

  .label {
    padding: 12px 16px;
    font-size: 1rem;
    font-weight: 600;
  }

  .value {
    padding: 12px 16px;
    border-radius: var(--border-radius);
    color: var(--font-black);
    font-size: 1rem;
    font-weight: 600;
    font-family: inherit;
  }
}
