.address-selector {
  min-width: 300px;
  max-width: 400px;
  max-height: 90vh;
  padding: 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: var(--border-radius);
  background: white;

  .header {
    padding: 4px 0;
    display: flex;
    align-items: center;
    gap: 8px;

    .title {
      margin: 12px 0;
    }
  }

  .address-list {
    height: 100%;
    padding: 12px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    border-radius: var(--border-radius);
    overflow-y: scroll;

    .address {
      background: var(--secondary-color);

      .header {
        display: flex;

        .name {
          font-weight: 600;
        }
      }
    }
  }

  .footer {
    margin-top: 16px;
    .action-buttons {
      display: flex;
      gap: 8px;
    }
  }
}

.address-card {
  display: flex;
  align-items: center;
  gap: 12px;

  .radio-btn {
    padding: 4px;
    border: 2px solid rgb(144, 144, 144);
    border-radius: var(--border-radius);

    &.selected {
      border: 2px solid var(--primary-color);
      background: var(--matrix-green);
    }
  }

  .address-wrapper {
    width: 300px;
    border-radius: var(--border-radius);
    box-sizing: border-box;
    cursor: pointer;

    &.selected {
      outline: 2px solid var(--primary-color);
    }

    &:hover {
      outline: 2px solid var(--primary-color);
    }
  }

  .note {
    margin-top: 8px;
    padding: 8px 16px;
    width: fit-content;
    height: fit-content;
    border: none;
    border-radius: var(--border-radius);
    background: var(--secondary-color);
    font-weight: 600;
  }
}
