.layout {
  height: 100%;
  display: grid;
  grid-template-rows: minmax(min-content, auto) 1fr;
}

.layout-body {
  min-height: 80vh;
  height: auto;
  padding-top: var(--header-height);
}
