.product {
  display: flex;
  flex-direction: column;
  color: var(--font-grey);
}

.product-image-container {
  position: relative;
  height: var(--product-height);
  width: var(--product-height);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: var(--border-radius);
  background: var(--secondary-color);
  overflow: hidden;
}

.product-image-container:hover {
  .product-image {
    transform: scale(1.04);
    transition: 0.5s ease;
  }
  .food-placeholder {
    transform: scale(1.12);
    transition: 0.5s ease;
  }
}

.product-image {
  height: 100%;
  width: 100%;
  max-height: var(--product-height);
  object-fit: cover;
  border-radius: var(--border-radius);
}

.product .favorite-icon {
  width: 20px;
  height: 20px;
  position: absolute;
  bottom: 5px;
  right: 5px;
  fill: var(--cherry-red);
  stroke: var(--cherry-red);
}

.product-title {
  margin: 5px 0;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.6;
  letter-spacing: -0.5px;
}

.product-description {
  margin: 4px 0;
  padding: 4px 8px;
  border-radius: var(--border-radius);
  font-size: 0.75rem;
  font-weight: 600;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: var(--font-grey);
  background: var(--secondary-color);
  width: fit-content;
}

.product-price {
  font-size: 1rem;
  margin: 8px 4px;
  font-weight: bold;
}

.out-of-stock-pill {
  width: fit-content;
  padding: 4px 8px;
  border-radius: var(--border-radius);
  font-size: 0.75rem;
  font-weight: 600;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: hsl(9, 83%, 44%);
  background: hsla(9, 82%, 57%, 0.392);
}

.out-of-stock-label {
  margin-bottom: 3px;
  display: flex;
  align-items: center;
  gap: 4px;
  color: #f5a623;

  svg {
    width: 14px;
    height: 12px;
    stroke-width: 3px;
  }

  .out-of-stock-text {
    font-size: 0.8rem;
    font-weight: 600;
    letter-spacing: 1px;
    text-transform: uppercase;
  }
}

@media only screen and (max-width: 800px) {
  .product-title {
    margin: 5px 0;
    font-size: var(--font-body-two);
    font-weight: 600;
  }

  .product-description {
    color: var(--font-grey);
    font-size: 0.625rem;
  }

  .product-price {
    font-size: var(--font-body-two);
    margin: 5px 0;
    font-weight: bold;
  }
}
