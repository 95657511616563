.edit-profile-page {
  margin: auto;
  height: 100%;
  width: 100%;
  max-width: 500px;
  box-sizing: border-box;
  color: var(--font-grey);
}

.edit-profile-body {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .camera-button {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: translateY(-180%);
    width: 32px;
    height: 32px;
    border: none;
    border-radius: 50%;
    background-color: #797c7f;

    svg {
      width: 1.125rem;
      height: 1.125rem;
      color: white;
    }
  }

  .camera-button:hover {
    background-color: #5a5c5e;
  }

  .edit-profile-fields {
    width: 100%;
    margin-top: 16px;

    .buttons {
      display: flex;
      margin-top: 16px;
      gap: 16px;
    }
  }
}
