.private-nav-bar {
  position: fixed;
  height: var(--header-height);
  width: 100%;
  display: flex;
  box-sizing: border-box;
  justify-content: space-between;
  padding: 0 32px;
  background: white;
  box-shadow: 0 3px 10px rgb(0 0 0 / 15%);
  z-index: 1;
}

.private-nav-bar .header-logo {
  width: 70px;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  text-decoration: none;
  font-size: var(--font-subheading-two);
  font-weight: 800;
  letter-spacing: 1px;
  color: var(--font-grey);
}

.private-nav-bar .private-nav-bar-menu {
  margin-right: 16px;
  width: 100%;
  justify-content: flex-end;
  gap: 8px;
}

.private-nav-bar .private-nav-bar-menu li a {
  padding: 12px 16px;
  border-radius: var(--border-radius);
  font-weight: 600;
  letter-spacing: 1px;
  color: var(--font-grey);
}

.private-nav-bar .private-nav-bar-menu li a:hover {
  background: var(--secondary-color);
}

.private-nav-bar ul {
  display: flex;
  align-items: center;
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.private-nav-bar ul li a {
  display: block;
  padding: 16px;
  font-size: 1rem;
  color: var(--font-grey);
  text-decoration: none;
  transition: 0.2s ease;
}

.private-nav-bar .private-nav-bar-buttons-list {
  display: flex;
  align-items: center;
  gap: 12px;
}
.private-nav-bar
  .private-nav-bar-buttons-list
  .private-nav-bar-buttons-list-item {
  padding: 8px;
  border-radius: var(--border-radius);
  cursor: pointer;
}

.private-nav-bar
  .private-nav-bar-buttons-list
  .private-nav-bar-buttons-list-item
  a {
  padding: 0;
}

.private-nav-bar
  .private-nav-bar-buttons-list
  .private-nav-bar-buttons-list-item:hover {
  background: var(--secondary-color);
}

.private-nav-bar
  .private-nav-bar-buttons-list
  .private-nav-bar-buttons-list-item:active {
  transform: scale(0.9);
  transition: 0.2s ease;
}

.current-tab-highlight span {
  font-weight: 500;
  transition: 0.2s ease;
}

.private-nav-bar .shop-panel {
  position: fixed;
  top: 80px;
  left: 5vw;
  height: auto;
  min-width: 80vw;
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  box-sizing: border-box;
  background: white;
  border-radius: 2px;
  z-index: 2;
  box-shadow: 0 3px 10px rgb(0 0 0 / 15%);
}

.private-nav-bar .shop-panel h2 {
  color: var(--font-grey);
  margin: 0px;
}

.private-nav-bar .shop-panel section {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.private-nav-bar .shop-panel .category-list {
  display: flex;
  gap: 8px;
}

.private-nav-bar .shop-panel .category-thumbnail {
  position: relative;
  width: 300px;
  height: 150px;
  border-radius: 5px;
  overflow: hidden;
  cursor: pointer;
}

.private-nav-bar .shop-panel .category-thumbnail .responsive-image {
  height: auto;
  max-width: 100%;
  object-fit: cover;
  filter: brightness(65%);
}

.private-nav-bar .shop-panel .category-thumbnail .responsive-image:hover {
  filter: brightness(35%);
  transition: 0.5s ease-in;
}

.private-nav-bar .shop-panel .category-thumbnail-text {
  position: absolute;
  bottom: 15px;
  right: 25px;
  color: white;
  font-size: var(--font-subheading-two);
  font-weight: 600;
  text-transform: uppercase;
}

.private-nav-bar .shop-panel .hot-items-list {
  display: flex;
  gap: 8px;
}

.private-nav-bar .shop-panel .hot-items-list a:hover {
  text-decoration: none;
}

.private-nav-bar .shop-panel .hot-items-list .product-image-container {
  overflow: hidden;
  cursor: pointer;
}

.private-nav-bar .shop-panel .hot-items-list img:hover {
  filter: brightness(65%);
  transition: 0.5s ease-in;
}

.private-nav-bar .shop-panel .all-products {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 35px 0 15px;
  font-weight: 600;
}
.private-nav-bar .shop-panel .all-products a {
  padding: 8px 16px;
  border-radius: var(--border-radius);
  color: var(--font-grey);
}
.private-nav-bar .shop-panel .all-products a:hover {
  background: var(--secondary-color);
  text-decoration: none;
}

.private-nav-bar .cart-button {
  position: relative;
}

.private-nav-bar .cart-button .notification {
  position: absolute;
  top: -8px;
  right: -8px;
  padding: 4px 8px;
  background: hsl(0, 60%, 60%);
  color: #fff;
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1rem;

  border-radius: 99999px;
}

.private-nav-bar .cart-button {
  display: flex;
  align-items: center;
}

.private-nav-bar .cart-button .cart-icon {
  width: 30px;
  height: 30px;
  color: var(--font-grey);
}

.private-nav-bar .menu-button {
  display: flex;
  align-items: center;
}

.private-nav-bar .menu-button .menu-icon {
  width: 30px;
  height: 30px;
  color: var(--font-grey);
}

@media screen and (min-width: 1200px) {
  .private-nav-bar .logo {
    position: absolute;
    max-height: 100%;
  }
}

@media screen and (max-width: 1200px) {
  .private-nav-bar {
    padding: 0 8px;
  }

  .private-nav-bar .logo {
    position: absolute;
    max-height: 100%;
  }

  .private-nav-bar .private-nav-bar-menu {
    display: none;
  }

  .private-nav-bar .private-nav-bar-buttons-list {
    gap: 4px;
  }

  .private-nav-bar .cart-button .cart-icon {
    width: 24px;
    height: 24px;
  }

  .private-nav-bar .menu-button .menu-icon {
    width: 24px;
    height: 24px;
  }
}

@media print {
  .private-nav-bar {
    display: none;
  }
}
