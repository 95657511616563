.introduction-page {
  .title {
    margin: 0;
    font-size: 2rem;
    font-weight: 600;
  }

  blockquote {
    height: 28vh;
    padding: 16px 0px 32px;
    margin: 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 16px;
  }

  p {
    margin: 0;
    font-size: 1.125rem;
    line-height: 1.75;
  }

  .actions {
    margin: 36px auto 0;
    width: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
  }

  @media screen and (max-width: 800px) {
    .actions a {
      width: 90%;
      font-size: 1rem;
    }
  }
}

.center-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 75vh;
}
