.contact-page {
  section {
    min-height: 30vh;
    box-sizing: border-box;
    padding: 0 5px;
  }

  .description {
    min-height: 30vh;
    padding: 0 8px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .description p {
    text-align: center;
    max-inline-size: 72ch;
    font-size: 1rem;
    line-height: 1.6;
  }

  .map-detail {
    padding: 0 16px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    line-height: 1.6;
  }

  .map-detail .map-detail-item .map-detail-item-title {
    font-weight: bold;
    letter-spacing: 2px;
    text-transform: uppercase;
  }

  .map-detail .map-detail-item .map-detail-item-description {
    margin: 5px 0;
    font-size: 1rem;
    max-inline-size: 66ch;
  }

  .tel {
    color: var(--primary-color);
  }

  .email {
    line-height: 2;
    color: var(--primary-color);
    font-size: 1rem;
  }

  .map {
    width: 100%;
    padding: 0 25px;
    box-sizing: border-box;
  }

  iframe {
    height: 100%;
    width: 100%;
  }

  @media only screen and (max-width: 800px) {
    .location {
      display: grid;
      grid-template-rows: 1fr 1fr;
      justify-items: center;
      gap: 50px;
    }
  }

  @media only screen and (min-width: 801px) {
    .location {
      display: grid;
      grid-template-columns: 1fr 1fr;
      justify-items: center;
    }
  }
}
