.products-interface .filter-list-title {
  font-size: 1rem;
  font-weight: 600;
}

.products-interface .filter-list-radios,
.products-interface .filter-list-checkboxes {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.products-interface .filter-list label {
  margin-left: 5px;
}

.products-interface .filter-list {
  input {
    cursor: pointer;
  }

  .filter-list-checkbox,
  .filter-list-radio {
    display: flex;
    align-items: center;
    cursor: pointer;
  }
}
