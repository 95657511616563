.outlet-information-page button {
  padding: 16px 32px;
  border-radius: var(--border-radius);
  border: none;
  background: var(--primary-color);
  color: white;
  font-size: 1rem;
  font-family: inherit;
  font-weight: 600;
  cursor: pointer;
}

.outlet-information-page button:disabled {
  background: #ddd;
  cursor: default;
}

.outlet-information-control {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.outlet-information-page fieldset {
  margin-bottom: 20px;
}
