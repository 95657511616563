.user-detail-page {
  height: 100%;
  width: 100%;
  margin: auto;
  max-width: var(--desktop-page-limit);
  color: var(--font-grey);
}

.user-detail-page .profile {
  min-height: 500px;
}

.user-detail-page .field {
  display: flex;
  line-height: 1.2;
}

.user-detail-page .field .label {
  width: 200px;
}

.user-detail-page .user-image-section {
  margin: 4px 0;
}
