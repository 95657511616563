.user-profile-placeholder {
  width: 100%;
  height: 100%;
  border-radius: 9999px;
  background: rgb(212, 214, 220);
  display: flex;
  align-items: center;
  justify-content: center;
}
.user-profile-placeholder svg {
  width: 50%;
  height: 50%;
  color: white;
}
