.manage-applications-page .input {
  width: 100%;
  padding: 16px;
  border: none;
  border-radius: var(--border-radius);
  box-sizing: border-box;
  background-color: rgb(239 241 245);
  font-size: 0.875rem;
  font-weight: 600;

  &::placeholder {
    color: grey;
    font-weight: 600;
  }
}
