.not-available-text {
  font-weight: 600;
  font-size: 0.75rem;
}

.rich-date-info {
  .primary {
    font-size: 0.925rem;
  }
}
