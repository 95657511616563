.products-interface {
  display: grid;
  grid-template-columns: 1fr 4fr;
  color: #707070;
}

@media only screen and (max-width: 800px) {
  .products-interface {
    grid-template-columns: none;
  }
}

@media only screen and (min-width: 1920px) {
  .products-interface {
    grid-template-columns: 1fr 4fr;
  }
}
