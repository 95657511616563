.landing-page-layout .footer,
.layout .footer {
  margin-top: 50px;
  font-size: var(--font-footer);
}

.landing-page-layout .footer .footer-grid,
.layout .footer .footer-grid {
  padding: 0 5vw;
  height: 30vh;
  display: grid;
  grid-template-columns: 20% 20% 60%;
  align-items: start;
  justify-items: start;
  background-color: white;
  color: var(--font-grey);
  border-top: 1px solid #ccc;
}

.landing-page-layout .footer .footer-title,
.layout .footer .footer-title {
  margin-top: 50px;
  margin-bottom: 20px;
  font-weight: 600;
}

.landing-page-layout .footer .footer-list,
.layout .footer .footer-list {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.landing-page-layout .footer a,
.layout .footer a {
  color: var(--font-grey);
}

.landing-page-layout .footer .footer-aside,
.layout .footer .footer-aside {
  padding: 0 5vw;
  color: var(--font-grey);
}

.landing-page-layout .footer #my-account,
.layout .footer #my-account {
  justify-self: center;
}

@media only screen and (max-width: 1200px) {
  .landing-page-layout .footer,
  .layout .footer {
    padding: 0 10px;
  }

  .landing-page-layout .footer-grid,
  .layout .footer-grid {
    grid-template-columns: 1fr 1fr 1fr !important;
    padding: 0 5px;
  }
}

@media print {
  .footer {
    display: none;
  }
}
