.address {
  max-width: 30vw;
  padding: 28px 20px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  border: 1px solid var(--secondary-color);
  border-radius: var(--border-radius);
  background: var(--secondary-color);
  color: var(--font-grey);

  &.red {
    background: rgb(252, 234, 238);
    outline: solid rgb(250 176 192);
  }

  &.blue {
    background: rgb(238, 242, 255);
    outline: solid rgb(184 199 247);
  }

  .header {
    display: flex;
    align-items: center;
    gap: 8px;

    svg {
      width: 18px;
      height: 18px;
    }

    .name {
      font-size: 1.125rem;
      font-weight: 600;
    }
  }

  .body {
    line-height: 1.6;
  }

  @media screen and (max-width: 800px) {
    max-width: 80vw;
  }
}
