.picture-grid .num-items-found-message {
  position: absolute;
  top: -25px;
  left: 25px;
}

@media only screen and (max-width: 800px) {
  .num-items-found-message {
    display: none;
  }
}
