.catalogue-section {
  .catalogue-header {
    height: 480px;
    background-size: cover;
    background-position: center;
    filter: brightness(95%);
  }

  .catalogue-header .text-layer {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 4px;
  }

  .catalogue-header .text-layer h2 {
    margin: 0;
    color: white;
    font-size: 3rem;
    font-weight: 400;
  }

  .catalogue-header .text-layer p {
    margin: 0;
    color: white;
    font-size: 1.25rem;
    font-weight: 400;
    line-height: 1.75;
    text-align: center;
  }

  .catalogue-body {
    margin: 24px 0;
    min-height: 60vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 32px;
  }

  .catalogue-body .food-list {
    display: none;
    padding: 0 24px;
    width: 100vw;
    display: flex;
    overflow-x: scroll;
    gap: 24px;
    box-sizing: border-box;

    a {
      text-decoration: none;
    }
  }

  @media only screen and (max-width: 800px) {
    .catalogue-header .text-layer h2 {
      font-size: 3.5rem;
    }

    .catalogue-header .text-layer p {
      font-size: 1.125rem;
    }

    .catalogue-body {
      margin: 12px 0 24px;
      grid-template-rows: 500px 1fr;
    }

    .catalogue-body p {
      padding: 0 16px;
      font-size: 1.125rem;
      max-inline-size: 40ch;
    }

    .catalogue-body .food-list {
      gap: 16px;
    }
  }
}
