.checkout-page {
  height: 100%;
  width: 100%;
  color: var(--font-grey);
  padding: 0 32px;
  box-sizing: border-box;
}

.checkout-page-header h1 {
  font-size: 1.75rem;
}

@media screen and (max-width: 800px) {
  .checkout-page {
    padding: 0 4px;
  }
  .checkout-page-header {
    padding: 0 5px;
  }
  .checkout-page-header h1 {
    font-size: 1.5rem;
  }
  .checkout-page-body {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
}

@media screen and (min-width: 800px) {
  .checkout-page-body {
    display: grid;
    grid-template-columns: 3fr 2fr;
    grid-gap: 15px;
    margin-right: 15px;
  }
}
